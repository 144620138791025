/* Right sidebar */
.right-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: $white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: $rightbar-width;
  z-index: 9999;
  float: right !important;
  right: -($rightbar-width + 10px);
  top: 0;
  bottom: 0;
  padding-bottom: 50px;

  .rightbar-title {
    background-color: $primary;
    padding: 27px 25px;
    color: $white;
  }
  .right-bar-toggle {
    background-color: lighten($dark,7%);
    height: 24px;
    width: 24px;
    line-height: 27px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;

    &:hover {
      background-color: lighten($dark,10%);
    }
  }

  .user-box {
    padding: 25px;
    text-align: center;

    .user-img {
        position: relative;
        height: 64px;
        width: 64px;
        margin: 0 auto 15px auto;

        .user-edit {
          position: absolute;
          right: -5px;
          bottom: 0px;
          height: 24px;
          width: 24px;
          background-color: $white;
          line-height: 24px;
          border-radius: 50%;
          box-shadow: $shadow-lg;
        }
    }
    h5 {
        margin-bottom: 2px;
        a {
            color: $dark;
        }
    }
  }

}

.right-bar-enabled {
  .right-bar {
    right: 0;
  }
}

// Timeline
.timeline-alt {
  padding: 20px 0;
  position: relative;

  &:before {
    background-color: $light;
    bottom: 30px;
    content: "";
    left: 9px;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0;
  }

  .timeline-item {
    position: relative;

    .timeline-icon {
      float: left;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid transparent;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
      background-color: $white;
    }

    .timeline-item-info {
      margin-left: 30px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .right-bar {
    overflow: auto;

    .slimscroll-menu {
      height: auto !important;
    }
  }
}