/* ===========
   Menu
 =============*/

.container-fluid {
    max-width: $layout-width;
}

.wrapper {
    padding-top: 71px;
}

#topnav {
    background: linear-gradient(135deg, $bg-topbar, $bg-topbar2);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1001;

    .has-submenu.active {
        a {
            color: $white;
            i {
                color: $white;
            }
        }
        .submenu {
            li.active>a {
                color: $primary;
            }
        }
    }
    .navbar-toggle {
        border: 0;
        position: relative;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover,
        &:focus {
            background-color: transparent;
            span {
                background-color: $white;
            }
        }
        .lines {
            width: 25px;
            display: block;
            position: relative;
            margin: 15px 10px 0 0;
            padding-top: 13px;
            height: 44px;
            -webkit-transition: all .5s ease;
            transition: all .5s ease;
        }
        span {
            height: 2px;
            width: 100%;
            background-color: rgba($white, 0.8);
            display: block;
            margin-bottom: 5px;
            -webkit-transition: -webkit-transform .5s ease;
            transition: -webkit-transform .5s ease;
            transition: transform .5s ease;
        }
    }
    .navbar-toggle.open {
        span {
            position: absolute;
            &:first-child {
                top: 18px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                visibility: hidden;
            }
            &:last-child {
                width: 100%;
                top: 18px;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
    .navigation-menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .navigation-menu>li {
        float: left;
        position: relative;
    }
    .navigation-menu>li>a {
        display: block;
        color: rgba($white, 0.7);
        font-size: 15px;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        line-height: 20px;
        padding-left: 25px;
        padding-right: 25px;
        i {
            font-size: 15px;
            margin-right: 8px;
            -webkit-transition: all .5s ease;
            transition: all .5s ease;
        }
    }
    .navigation-menu>li>a:hover,
    .navigation-menu>li>a:focus {
        background-color: transparent;
    }
}


/*
    Responsive Menu
  */

@media (min-width: 992px) {
    #topnav {
        .navigation-menu>li>a {
            padding-top: 26px;
            padding-bottom: 25px;
            &:hover,
            &:focus,
            &:active {
                color: $white;
            }
        }
        .topbar-menu {
            padding-bottom: 10px;
        }
        .navigation-menu>li:first-of-type>a {
            padding-left: 0;
        }
        .navigation-menu>li.last-elements .submenu {
            left: auto;
            right: 0;
        }
        .navigation-menu>li.last-elements .submenu>li.has-submenu .submenu {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: 10px;
        }
        .navigation-menu>li:hover a {
            color: $white;
            i {
                color: $white;
            }
        }
        .navigation-menu>li>ul>li.has-submenu:active>a,
        .navigation-menu>li>ul>li.has-submenu:hover>a {
            color: $primary;
        }
        .navigation-menu>li .submenu {
            position: absolute;
            top: 90%;
            left: 0;
            z-index: 1000;
            padding: 10px 0;
            list-style: none;
            min-width: 200px;
            text-align: left;
            visibility: hidden;
            opacity: 0;
            margin-top: 10px;
            border-radius: 4px;
            -webkit-transition: all .2s ease;
            transition: all .2s ease;
            background-color: $white;
            box-shadow: $shadow-lg;
            &:before {
                left: 20px;
                top: -10px;
                content: "";
                display: block;
                position: absolute;
                background-color: transparent;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid $white;
                z-index: 9999;
            }
        }
        .navigation-menu>li .submenu.megamenu {
            white-space: nowrap;
            width: auto;
        }
        .navigation-menu>li .submenu.megamenu>li {
            overflow: hidden;
            width: 200px;
            display: inline-block;
            vertical-align: top;
        }
        .navigation-menu>li .submenu>li.has-submenu>a:after {
            content: "\F142";
            font-family: "Material Design Icons";
            position: absolute;
            right: 20px;
            top: 5px;
            font-size: 18px;
        }
        .navigation-menu>li .submenu>li .submenu {
            left: 100%;
            top: 0;
            margin-left: 10px;
            margin-top: -1px;
            &:before {
                display: none !important;
            }
        }
        .navigation-menu>li .submenu li {
            position: relative;
        }
        .navigation-menu>li .submenu li ul {
            list-style: none;
            padding-left: 0;
            margin: 0;
        }
        .navigation-menu>li .submenu li a {
            display: block;
            padding: 9px 25px;
            clear: both;
            white-space: nowrap;
            color: $dark;
            -webkit-transition: all .35s ease;
            transition: all .35s ease;
        }
        .navigation-menu>li .submenu li a:hover {
            color: $primary;
        }
        .navigation-menu>li .submenu li span {
            display: block;
            padding: 8px 25px;
            clear: both;
            line-height: 1.42857143;
            white-space: nowrap;
            font-size: 10px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 500;
            color: $dark;
        }
        .navbar-toggle {
            display: none;
        }
    }
    #topnav #navigation {
        display: block !important;
    }
}

@media (max-width: 991px) {
    .wrapper {
        padding-top: 70px;
    }
    .container {
        width: auto !important;
    }
    #topnav {
        .navigation-menu {
            float: none;
            max-height: 400px;
            text-align: left;
        }
        .has-submenu.active {
            a {
                color: $primary;
                i {
                    color: $primary;
                }
            }
            .submenu {
                li.active>a {
                    color: $primary;
                }
            }
        }
        .navigation-menu>li {
            display: block;
            float: none;
        }
        .navigation-menu>li>a {
            color: $dark;
            padding: 15px;
            i {
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 0;
                vertical-align: inherit;
            }
        }
        .navigation-menu>li>a:after {
            position: absolute;
            right: 15px;
        }
        .navigation-menu>li .submenu {
            display: none;
            list-style: none;
            padding-left: 20px;
            margin: 0;
        }
        .navigation-menu>li .submenu li a {
            display: block;
            position: relative;
            padding: 7px 20px;
            color: $dark;
        }
        .navigation-menu>li .submenu li a:hover {
            color: $primary;
        }
        .navigation-menu>li .submenu li.has-submenu>a:after {
            content: "\F140";
            font-family: "Material Design Icons";
            position: absolute;
            right: 30px;
        }
        .navigation-menu>li .submenu.open {
            display: block;
        }
        .navigation-menu>li .submenu .submenu {
            display: none;
            list-style: none;
        }
        .navigation-menu>li .submenu .submenu.open {
            display: block;
        }
        .navigation-menu>li .submenu.megamenu>li>ul {
            list-style: none;
            padding-left: 0;
        }
        .navigation-menu>li .submenu.megamenu>li>ul>li>span {
            display: block;
            position: relative;
            padding: 15px;
            text-transform: uppercase;
            font-size: 11px;
            letter-spacing: 2px;
            color: $dark;
        }
        .navigation-menu>li.has-submenu.open>a {
            color: $primary;
        }
        .navbar-header {
            float: left;
        }
    }
    #navigation {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        background-color: $white;
    }
    #navigation.open {
        display: block;
        overflow-y: auto;
    }
    #topnav .has-submenu.active a {
        color: $primary;
        &:active,
        &:focus,
        i {
            color: $primary;
        }
    }
}

@media (min-width: 768px) {
    #topnav .navigation-menu>li.has-submenu:hover>.submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }
    #topnav .navigation-menu>li.has-submenu.last-elements:hover>.submenu {
        &:before {
            right: 20px;
            left: auto;
        }
    }
    #topnav .navigation-menu>li.has-submenu:hover>.submenu>li.has-submenu:hover>.submenu {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }
    .navbar-toggle {
        display: block;
    }
}

@media (max-height: 320px) {
    .navigation-menu {
        max-height: 240px !important;
    }
}