
// 
// footer.scss
//

.footer {
  border-top: 1px solid rgba($gray-600,0.2);
  bottom: 0;
  padding: 19px 0;
  position: absolute;
  right: 0;
  color: $gray-600;
  left: 0;
  background-color: $white;
}