
// 
// alerts.scss
//

// Toastr Notification
.jq-toast-single {
  padding: 15px;
  font-family: $font-family-base;
  background-color: $cyan;
  font-size: 13px;
  line-height: 22px;

  h2 {
    font-family: $font-secondary;
  }

  a{
    font-size: 14px;
    &:hover {
      color: $white;
    }
  }
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
}

.jq-icon-info {
  background-color: $info;
  color: $white;
  border-color: $info;
}

.jq-icon-success {
  background-color: $success;
  color: $white;
  border-color: $success;
}

.jq-icon-warning {
  background-color: $warning;
  color: $white;
  border-color: $warning;
}

.jq-icon-error {
  background-color: $danger;
  color: $white;
  border-color: $danger;
}

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: lighten($dark,10%);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}




