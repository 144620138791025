// 
// dashboard.scss
//


// Dashboard charts
.dash-sales-chart {
    height: 165px;
}

.high-performing-product {
    height: 309px;
}

.conversion-chart {
    height: 309px;
}

.dash-doughnut {
    height: 193px;
}

.dash-usa-map {
    height: 300px;
}

.datauses-area {
    height: 198px;
}