// 
// faq.scss
//

.question-q-box {
    height: 30px;
    width: 30px;
    color: $white;
    background-color: $cyan;
    text-align: center;
    border-radius: 50%;
    float: left;
    line-height: 30px;
}

.question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: 400;
    font-size: 16px;
}

.answer {
    margin-left: 50px;
    color: $gray-600;
    margin-bottom: 40px;
}