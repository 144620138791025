// 
// timeline.scss
//

.timeline {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    margin-bottom: 50px;
    position: relative;
    table-layout: fixed;
    width: 100%;
    .time-show {
        margin-bottom: 30px;
        margin-right: -75px;
        margin-top: 30px;
        position: relative;
    }
    &:before {
        background-color: $info;
        bottom: 0;
        content: "";
        left: 50%;
        position: absolute;
        top: 30px;
        width: 2px;
        z-index: 0;
    }
    .timeline-icon {
        -webkit-border-radius: 50%;
        background: $light;
        border-radius: 50%;
        display: block;
        height: 20px;
        left: -54px;
        margin-top: -10px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 20px;
        i {
            color: $white;
            font-size: 13px;
            margin-top: 1px;
            position: absolute;
            left: 4px;
        }
    }
    .time-icon {
        &:before {
            font-size: 16px;
            margin-top: 5px;
        }
    }
}

h3.timeline-title {
    color: $gray-300;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.timeline-item {
    display: table-row;
    &:before {
        content: "";
        display: block;
        width: 50%;
    }
    .timeline-desk {
        .arrow {
            border-bottom: 12px solid transparent;
            border-right: 12px solid $white !important;
            border-top: 12px solid transparent;
            display: block;
            height: 0;
            left: -12px;
            margin-top: -12px;
            position: absolute;
            top: 50%;
            width: 0;
        }
        .timeline-box {
            padding: 20px;
        }
    }
    .timeline-date {
        margin-bottom: 10px;
    }
}

.timeline-item.alt {
    &:after {
        content: "";
        display: block;
        width: 50%;
    }
    .timeline-desk {
        .arrow-alt {
            border-bottom: 12px solid transparent;
            border-left: 12px solid $white !important;
            border-top: 12px solid transparent;
            display: block;
            height: 0;
            left: auto;
            margin-top: -12px;
            position: absolute;
            right: -12px;
            top: 50%;
            width: 0;
        }
        .album {
            float: right;
            margin-top: 20px;
            a {
                float: right;
                margin-left: 5px;
            }
        }
    }
    .timeline-icon {
        left: auto;
        right: -56px;
    }
    &:before {
        display: none;
    }
    .panel {
        margin-left: 0;
        margin-right: 45px;
    }
    h4,
    p,
    .timeline-date {
        text-align: right;
    }
}

.timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
    h4 {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
    }
    .panel {
        background: lighten($light, 3%);
        display: block;
        margin-bottom: 5px;
        margin-left: 45px;
        position: relative;
        text-align: left;
        border: 0;
    }
    h5 {
        span {
            color: $gray-300;
            display: block;
            font-size: 12px;
            margin-bottom: 4px;
        }
    }
    p {
        font-size: 14px;
        margin-bottom: 0;
    }
    .album {
        margin-top: 12px;
        a {
            float: left;
            margin-right: 5px;
        }
        img {
            height: 36px;
            width: auto;
            border-radius: 3px;
        }
    }
    .notification {
        background: none repeat scroll 0 0 $white;
        margin-top: 20px;
        padding: 8px;
    }
}  